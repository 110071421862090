import { BleClient } from '@capacitor-community/bluetooth-le';
import { DEFAULT_RSSI, Device } from '@app/models/device';
import { DeviceStatus } from '@app/enums/device-status.enum';
import { DevicesService } from '../devices/devices.service';
import { Injectable, inject } from '@angular/core';
import {
  Subscription,
  catchError,
  concatMap,
  filter,
  from,
  interval,
  map,
  mergeMap,
  retryWhen,
  switchMap,
  timer
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WatchdogService {
  private readonly devicesService: DevicesService = inject(DevicesService);
  private readonly maxReconnectionDelay = 60000; // Max 1 minute
  private readonly reconnectionFactor = 2; // Exponential factor
  private connectionCheckIncreaseFactor = 1.2; // Double the interval each time
  private connectionCheckInProgress: boolean = false;
  private connectionCheckInterval = 10000; // Check every 10 seconds
  private connectionCheckSubscription: Subscription | null = null;
  private maxConnectionCheckInterval = 30000; // Maximum check interval: 30 seconds
  private reconnectInProgress: boolean = false;
  private reconnectionDelay = 2000; // Start with 2 seconds
  private reconnectSubscription: Subscription | null = null;

  public initialize(): void {
    this.devicesService.currentDevice
      .pipe(
        filter((device: Device | null): device is Device => device !== null),
        map(device => device as Device)
      )
      .subscribe(async (device: Device) => {
        if (device.status === DeviceStatus.NOTCONNECTED) {
          this.stopConnectionCheck();
          setTimeout(() => this.tryReconnect(device), 5000);
        }
        if (device.status === DeviceStatus.CONNECTED) {
          this.stopReconnect();
          setTimeout(() => this.startConnectionCheck(device), 5000);
        }
        if (!device || device.status === DeviceStatus.CONNECTING) {
          this.stopReconnect();
          this.stopConnectionCheck();
        }
      });
  }

  public stopWatchDog(): void {
    this.stopReconnect();
    this.stopConnectionCheck();
  }

  /*
   * Used to attempt to reconnect to a device
   */
  private tryReconnect(device: Device): void {
    if (this.reconnectInProgress) {
      return;
    }
    console.log('🚀 ~ WatchdogService ~ tryReconnect ~ Attempting to reconnect...');
    this.reconnectInProgress = true;
    from(BleClient.getDevices([device.deviceId]))
      .pipe(
        switchMap(devices => {
          if (devices.length > 0 && devices.find(d => d.deviceId === device.deviceId)) {
            // Device is available, try to reconnect
            return from(BleClient.connect(device.deviceId));
          } else {
            // Device is not available, throw an error to trigger retry
            throw new Error('Device is not available');
          }
        }),
        retryWhen(errors =>
          errors.pipe(
            mergeMap((error, i) => {
              const delay = Math.min(this.reconnectionDelay * this.reconnectionFactor ** i, this.maxReconnectionDelay);
              console.log(`🚀 ~ WatchdogService ~ tryReconnect ~ Attempt ${i + 1}: retrying in ${delay}ms`);
              return timer(delay);
            })
          )
        )
      )
      .subscribe({
        complete: async () => {
          console.log('🚀 ~ WatchdogService ~ tryReconnect ~ Reconnected successfully');
          await this.devicesService.connectToDevice(device);
          this.devicesService.currentDevice = { ...device, status: DeviceStatus.CONNECTED };
          await this.devicesService.fetchDeviceData();
          this.reconnectionDelay = 2000; // Reset reconnection delay
          this.connectionCheckInterval = 10000; // Reset connection check interval
          this.reconnectSubscription?.unsubscribe(); // Unsubscribe after successful reconnection
          this.reconnectSubscription = null;
          this.reconnectInProgress = false;
        },
        error: (error: any) => {
          console.log('🚀 ~ WatchdogService ~ tryReconnect ~ Failed to reconnect', error);
          this.reconnectInProgress = false;
        }
      });
  }

  public stopReconnect(): void {
    this.reconnectSubscription?.unsubscribe();
    this.reconnectSubscription = null;
    this.reconnectInProgress = false;
  }

  /*
   * Used to check the connection status of a device by reading the rssi value
   */
  private startConnectionCheck(device: Device): void {
    if (this.connectionCheckInProgress) {
      return;
    }
    this.connectionCheckInProgress = true;
    this.connectionCheckSubscription = interval(this.connectionCheckInterval)
      .pipe(
        concatMap(() =>
          from(BleClient.readRssi(device.deviceId)).pipe(
            catchError(error => {
              console.error('🚀 ~ WatchdogService ~ startConnectionCheck ~ Failed to read battery level', error);
              this.devicesService.currentDevice = {
                ...device,
                status: DeviceStatus.NOTCONNECTED,
                batteryLevel: null,
                rssi: DEFAULT_RSSI
              };
              // Instead of returning EMPTY, return a timer to delay the next operation
              // This effectively skips the current cycle but ensures the interval timing remains consistent
              return timer(this.connectionCheckInterval);
            }),
            // Ensure there's a delay equal to the interval before proceeding, accounting for operation time
            concatMap(result => {
              // Increase the interval each time the connection check succeeds
              this.connectionCheckInterval = Math.min(
                this.connectionCheckInterval * this.connectionCheckIncreaseFactor,
                this.maxConnectionCheckInterval
              );
              console.log(
                '🚀 ~ WatchdogService ~ startConnectionCheck ~ this.connectionCheckInterval increase to:',
                this.connectionCheckInterval
              );
              return timer(this.connectionCheckInterval).pipe(map(() => result));
            })
          )
        )
      )
      .subscribe(() => {
        this.connectionCheckInProgress = false;
      });
  }

  private stopConnectionCheck(): void {
    this.connectionCheckSubscription?.unsubscribe();
    this.connectionCheckSubscription = null;
  }
}
